<template>
  <div class="main">
    <!-- 排序 -->
    <!-- <div class="sort_area">
      <div style="margin-top: 20px">
        排序：
        <el-radio-group v-model="sortType" size="small" @change="getFaguiListData(1)" v-if="hasSearchThis">
          <el-radio-button label="0">按时间</el-radio-button>
          <el-radio-button label="1">按热度</el-radio-button>
        </el-radio-group>
        <el-radio-group v-model="sortType" size="small" @change="getFaguiListData(1)" v-else>
          <el-radio-button label="2">按匹配度</el-radio-button>
          <el-radio-button label="0">按时间</el-radio-button>
        </el-radio-group>
      </div>
    </div> -->
    <!--  政策文件  -->
    <div :class="{ 'tall': loading }">
      <div v-if="flag" v-loading="loading">
        <div class="fagui_area flex col" v-for="item in faguiList" :key="item.id" @click="goToFGDetail(item.id)">
          <div class="fagui_title flex row between">
            <div v-html="item.title"></div>
            <div> > </div>
          </div>
          <div class="fagui_info flex row start mar-r30">
            <div>{{ item.fwDepartment }}</div>
            <div>{{ item.dispatchDate }}</div>
          </div>
        </div>
        <div class="line"></div>
      </div>
      <!--  分页  -->
      <el-pagination
        v-if="faguiList.length > 0"
        background
        layout="prev, pager, next"
        :page-size="pageSize"
        :total="pageListTotal"
        :current-page="currentPage"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
      <!-- 无内容时的显示 -->
      <div v-if="!flag">
        <el-empty description="暂无内容"></el-empty>
      </div>
    </div>
  </div>
</template>

<script>
import { request } from '@/network';
import { mapState } from 'vuex';
import { getMemberId } from '@/assets/public/utils/token';
export default {
  name: 'fagui',
  data () {
    return {
      sortType: 0,
      faguiList: [],
      pageListTotal: 0,
      pageSize: 10,
      currentPage: 1,
      keyWord: this.search,
      chooseCity: JSON.parse(window.sessionStorage.getItem('choosecity')),
      hasSearchThis: true,
      loading: false,
      flag: true,
      isFirst: true
    };
  },
  activated () {
    // this.keyWord = this.search
    if (this.isFirst) return;
    this.getFaguiListData(this.currentPage, localStorage.getItem('selectCity'));
  },
  mounted () {
    this.getFaguiListData(1, localStorage.getItem('selectCity'));
  },
  computed: {
    ...mapState('policy', [ 'search' ]),
    ...mapState({
      selectCity: (state) => state.menu.selectCity
    })
  },
  methods: {
    async getFaguiListData (page, city) {
      this.loading = true;
      const postData = {
        pageNo: page,
        pageSize: this.pageSize,
        sortType: this.sortType,
        city: city,
        memberId: getMemberId()
      };
      postData.keyWord = this.search;
      const { data: res } = await request({
        method: 'POST',
        url: '/pcp/policylibrary/getRule',
        data: postData
      });
      const isDetailBack = JSON.parse(localStorage.getItem('isDetailBack')); // 如果是detail页面跳转回来的话就不回到顶部
      if (!this.isFirst && !isDetailBack) {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      }
      localStorage.setItem('isDetailBack', false);
      if (res.code === 200) {
        const { policyList, totalSize } = res.data;
        this.faguiList = policyList;
        this.pageListTotal = totalSize;
        this.flag = policyList.length > 0;
        this.currentPage = page;
        this.isFirst = false;
        this.loading = false;
      }
    },
    goToFGDetail (id) {
      this.$router.push({
        path: '/faguidetails',
        query: {
          id: id
        }
      });
    },
    handleCurrentChange (val) {
      this.getFaguiListData(val, localStorage.getItem('selectCity'));
    }
  },
  watch: {
    search (newVal) {
      this.keyWord = newVal;
      this.hasSearchThis = this.search === '';
      this.getFaguiListData(1, localStorage.getItem('selectCity'));
    },
    selectCity: {
      handler (val) {
        if (!this.isFirst) this.getFaguiListData(1, val);
      },
      immediate: true
    }
  }
};
</script>

<style lang="less" scoped>
.main {
  width: 1200px;
  min-height: 500px;
}
.sort_area {
  color:#C2C2C2;
  font-size: 14px;
  margin-bottom: 20px;
  &>div {
    margin-right: 50px;
    /deep/.el-radio-button__orig-radio:checked+.el-radio-button__inner {
      background-color: #fff;
      color: #156ED0;
      border-color: #156ED0;
    }
    /deep/.el-radio-button__inner:hover {
      color: #156ED0;
    }
  }
}
.fagui_area {
  border-top: 2px solid #D4D6DA;
  >div {
    width: 100%;
  }
  .fagui_title {
    height: 40px;
    line-height: 40px;
    margin-top: 30px;
    font-size:18px;
    cursor: pointer;
    >div:nth-child(1) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    >div:nth-child(2) {
      color: #B2B2B2;
    }
  }
  .fagui_info {
    height: 20px;
    line-height: 20px;
    font-size: 14px;
    color: #B8B8B8;
    margin-bottom: 38px;
  }
}
.line {
  border-top: 2px solid #D4D6DA;
  margin-bottom: 40px;
}
.el-pagination {
  text-align: center;
  padding-bottom: 50px;
}
.tall{
  // height: 500px;
}
</style>
